import GlobalStats from 'features/App/GlobalStats/index'
import EventStats from 'features/App/EventStats/index'

const ROUTES = [
  {
    name: 'default',
    route: '/',
    component: GlobalStats
  },
  {
    name: 'eventStats',
    route: '/events/:id',
    component: EventStats
  }
]

export default ROUTES
