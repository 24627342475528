import React from "react";
import { DateRangePicker } from "blt-react-dates";
import moment from "moment";
import omit from "lodash/omit";
import { START_DATE, END_DATE } from "blt-react-dates/src/constants";
import { withTranslation } from "react-i18next";

const defaultProps = {
  autoFocus: true,
  block: true,
  startDateId: START_DATE,
  endDateId: END_DATE,
  numberOfMonths: 2,
  keepOpenOnDateSelect: true,
  reopenPickerOnClearDates: false,
  displayFormat: () => "DD/MM/YYYY HH:mm",
  monthFormat: "MMMM YYYY",
  stateDateWrapper: (date) => date,
};

class DateRangePickerWrapper extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = START_DATE;
    } else if (props.autoFocusEndDate) {
      focusedInput = END_DATE;
    }
    this.state = {
      focusedInput,
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      this.setState({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      });
    }
  }

  onDatesChange = ({ startDate, endDate }) => {
    const { stateDateWrapper } = this.props;
    this.setState(
      {
        startDate: startDate && stateDateWrapper(startDate),
        endDate: endDate && stateDateWrapper(endDate),
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  onFocusChange = (focusedInput) => {
    if (!focusedInput) return;
    this.setState({ focusedInput });
  };

  render() {
    const { t, i18n } = this.props;
    let { focusedInput, startDate, endDate } = this.state;

    const props = omit(this.props, [
      "autoFocus",
      "autoFocusEndDate",
      "initialStartDate",
      "initialEndDate",
      "stateDateWrapper",
      "onChange",
    ]);
    return (
      <>
        <DateRangePicker
          {...props}
          startDate={startDate || null}
          endDate={endDate || null}
          onDatesChange={this.onDatesChange}
          startDatePlaceholderText={t("calendar.start")}
          endDatePlaceholderText={t("calendar.end")}
          phrases={{
            closeDatePicker: t("calendar.close"),
            clearDates: t("calendar.reset"),
          }}
          focusedInput={focusedInput}
          onFocusChange={this.onFocusChange}
          verticalSpacing={1}
          isOutsideRange={() => false}
          minimumNights={0}
          initialVisibleMonth={() =>
            moment().locale(i18n.language).subtract(1, "months")
          }
        />
      </>
    );
  }
}

DateRangePickerWrapper.defaultProps = defaultProps;

export default withTranslation("common")(DateRangePickerWrapper);
