import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import SubFilter from "components/SubFilter";
import EmptyTable from "components/EmptyTable";
import CustomSearch from "components/CustomSearch";

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: this.props.active,
      data: this.props.data,
      filterData: this.props.data,
      selected: this.props.selected,
      columns: this.props.columns,
      extraClasses: this.props.extraClasses,
      placeholderSearchBar: this.props.placeholderSearchBar,
      filters: this.props.filters,
      backLabel: this.props.backLabel,
      continueLabel: this.props.continueLabel,
      count: this.props.count,
      submit: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let subfilters = [...this.props.filters];
      let filterD = [...this.state.filterData];
      if (this.props.restore) {
        subfilters.map((el) => {
          return (el.filterActive = false);
        });
        filterD = this.props.data;
      }
      this.setState({
        active: this.props.active,
        data: this.props.data,
        selected: this.props.selected,
        columns: this.props.columns,
        filters: subfilters,
        filterData: filterD,
        submit: false,
      });
    }
  }

  _onChangeSubFilters = (filterData, filters, selected) => {
    this.setState(
      {
        filterData,
        filters,
        selected,
        submit: false,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  handleCancel = () => {
    let subfilters = [...this.state.filters];
    subfilters.map((el) => {
      return (el.filterActive = false);
    });
    this.setState(
      {
        active: false,
        filterData: this.state.data,
        filters: subfilters,
        selected: [],
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  handleContinue = () => {
    this.setState(
      {
        submit: true,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  _onRowSelect = (row, isSelect, rowIndex, e) => {
    let ids = [...this.state.selected];
    if (row.id === undefined) {
      row.id = "0";
    }
    if (isSelect) {
      ids.push(row.id);
    } else {
      let index = ids.indexOf(row.id);
      ids.splice(index, 1);
    }

    this.setState(
      {
        selected: ids,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  _onSelectAll = (isSelect, rows, e) => {
    const ids = rows.map((r) => {
      if (r.id === undefined) {
        r.id = "0";
      }
      return r.id;
    });
    let ids_selected = isSelect ? ids : [];
    this.setState(
      {
        selected: ids_selected,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  selectRow = (props) => {
    let selectRow = {
      mode: "checkbox",
      clickToSelect: true,
      selected: this.state.selected,
      onSelect: (row, isSelect, rowIndex, e) => {
        this._onRowSelect(row, isSelect, rowIndex, e);
      },
      onSelectAll: (isSelect, rows, e) => {
        if (props.searchText === "") {
          this._onSelectAll(isSelect, this.state.filterData, e);
        } else {
          this._onSelectAll(isSelect, rows, e);
        }
      },
    };
    return selectRow;
  };
  render() {
    const { t } = this.props;

    const CustomTotal = (props) => (
      <>
        <span className="react-bootstrap-table-pagination-total">
          {t("data_table.show_results_1")}
        </span>
        <span className="react-bootstrap-table-pagination-subtotal">
          {t("data_table.show_results_2")}
        </span>
      </>
    );

    const options = {
      showTotal: true,
      paginationTotalRenderer: CustomTotal,
    };

    return (
      <>
        {this.state.active && (
          <ToolkitProvider
            keyField="id"
            data={this.state.filterData}
            columns={this.state.columns}
            search
          >
            {(props) => (
              <div className={`c-results ${this.state.extraClasses}`}>
                <CustomSearch {...props.searchProps} />

                {this.state.filters && (
                  <SubFilter
                    filters={this.state.filters}
                    data={this.state.data}
                    onChange={this._onChangeSubFilters}
                  />
                )}
                {this.state.filterData.length > 0 && (
                  <>
                    <BootstrapTable
                      classes="o-table--list c-results__table"
                      keyField="id"
                      data={this.state.filterData}
                      columns={this.state.columns}
                      selectRow={this.selectRow(props.searchProps)}
                      pagination={
                        this.state.filterData.length > 10
                          ? paginationFactory(options)
                          : null
                      }
                      {...props.baseProps}
                    />
                    <div className="c-results__button-row">
                      {this.state.backLabel && (
                        <button
                          className="u-button--link u-button--base"
                          onClick={this.handleCancel}
                        >
                          {this.state.backLabel}
                        </button>
                      )}

                      {this.state.continueLabel && (
                        <button
                          className="u-button--secondary u-button--base"
                          onClick={this.handleContinue}
                        >
                          {this.state.continueLabel}
                        </button>
                      )}
                    </div>
                  </>
                )}
                {this.state.filterData.length === 0 && <EmptyTable />}
              </div>
            )}
          </ToolkitProvider>
        )}
      </>
    );
  }
}
export default withTranslation("common")(DataTable);
