import React from "react";

import { useTranslation } from "react-i18next";

function Header(props) {
  const { i18n } = useTranslation("common");

  function switchLanguage(lang) {
    console.log(i18n.changeLanguage(lang));
  }

  function handleSwitchEs(event) {
    event.preventDefault();
    switchLanguage("es");
  }
  function handleSwitchEn(event) {
    event.preventDefault();
    switchLanguage("en");
  }

  return (
    <ul className="c-header__links">
      <li className="c-header__single is-language">
        <button onClick={handleSwitchEs}>
          ES
          <span className="c-header__flag" role="img" aria-label="Español">🇲🇽</span>
        </button>
        <span>|</span>
        <button onClick={handleSwitchEn}>
          EN
          <span className="c-header__flag" role="img" aria-label="English">🇺🇸</span>
        </button>
      </li>
    </ul>
  );
}

export default Header;
