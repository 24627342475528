import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import DateRangePickerWrapper from "components/DateRangePickerWrapper";

class CustomCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      startDate: "",
      endDate: "",
      filters: props.filters,
      label: "",
      continueLabel: props.continueLabel,
      backLabel: props.backLabel,
      submit: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate ||
      prevProps.active !== this.props.active
    ) {
      let preLabel = this.state.label;
      let subfilters = [...this.props.filters];
      if (this.props.restore) {
        subfilters.map((el) => {
          return (el.filterActive = false);
        });
        preLabel = null;
      }
      this.setState({
        active: this.props.active,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        filters: subfilters,
        continueLabel: this.props.continueLabel,
        backLabel: this.props.backLabel,
        label: preLabel,
        submit: false,
      });
    }
  }

  _setData = (filter, i) => {
    let subfilters = [...this.state.filters];
    let newState = !filter.filterActive;
    let newLabel = null;
    let newStartDate = null;
    let newEndDate = null;

    if (newState) {
      newLabel = filter.label;
      newStartDate = filter.startDate;
      newEndDate = filter.endDate;
    }

    subfilters.map((el) => {
      return (el.filterActive = false);
    });

    subfilters[i].filterActive = newState;
    this.setState(
      {
        startDate: newStartDate,
        endDate: newEndDate,
        filters: subfilters,
        label: newLabel,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  _setCalendarDates = (params) => {
    let subfilters = [...this.state.filters];
    subfilters.map((el) => {
      return (el.filterActive = false);
    });

    this.setState(
      {
        startDate: params.startDate,
        endDate: params.endDate,
        filters: subfilters,
        label: null,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  handleCancel = () => {
    let subfilters = [...this.state.filters];
    subfilters.map((el) => {
      return (el.filterActive = false);
    });
    this.setState(
      {
        active: false,
        filters: subfilters,
        startDate: "",
        endDate: "",
        label: "",
        cancel: true,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  handleContinue = () => {
    this.setState(
      {
        submit: true,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.active && (
          <>
            <div className="c-results is-calendar">
              {this.state.filters && (
                <div className="c-results__button-group">
                  {this.state.filters.map((el, i) => (
                    <button
                      key={i}
                      className={
                        el.filterActive
                          ? "c-results__button is-active"
                          : "c-results__button"
                      }
                      onClick={() => this._setData(el, i)}
                    >
                      {t(el.label)}
                    </button>
                  ))}
                </div>
              )}
              <h4 className="u-section-space">{t("custom_range")}</h4>
              <DateRangePickerWrapper
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this._setCalendarDates}
              />
              <div className="c-results__button-row is-calendar_button">
                {this.state.backLabel && (
                  <button
                    className="u-button--link u-button--base"
                    onClick={this.handleCancel}
                  >
                    {this.state.backLabel}
                  </button>
                )}

                {this.state.continueLabel && (
                  <button
                    className="u-button--secondary u-button--base"
                    onClick={this.handleContinue}
                  >
                    {this.state.continueLabel}
                  </button>
                )}
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withTranslation("common")(CustomCalendar);
