import React from 'react'

import {withTranslation} from "react-i18next"

class EmptyTable extends React.Component {
  render () {

    const {t} = this.props

    return (
      <div className="c-empty--table">
        <h3>{t("empty_filter_title")}</h3>
        <p>{t("empty_filter_desc")}</p>
      </div>
    )
  }
}

export default withTranslation("common")(EmptyTable)
