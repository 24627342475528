import React, { Component } from 'react'

export default class CustomSearch extends Component {
  constructor(props) {
    super(props)
    this.input = React.createRef();
    this.state = {
      props: this.props
    }
  }

  _handleChange = () => {
    this.props.onSearch(this.input.current.value.normalize('NFD').replace(/[\u0300-\u036f]/g, ""))
  }

  render () {
    return (
      <div>
        <input
          className= "form-control c-results__search"
          placeholder={this.state.placeholderSearchBar}
          ref={this.input}
          type="text"
          onChange={this._handleChange}
        />
      </div>
    )
  }
}
