import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import CurrencyNumber from "components/CurrencyNumber";

class StatsByPaymentMethod extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethods: this._formatPaymentMethods(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        paymentMethods: this._formatPaymentMethods(),
      });
    }
  }

  _formatPaymentMethods = () => {
    let pmData = [...this.props.data];

    pmData.forEach((el) => {
      el["shortName"] = `${
        "payment_methods." +
        (el.paymentMethodType && el.paymentMethodType.split("::")[1])
      }`;
      if (this.props.paymentMethodOrder) {
        el["i"] = this.props.paymentMethodOrder[el["shortName"]];
      }
    });
    pmData.sort(function (a, b) {
      return a.i - b.i;
    });
    return pmData;
  };

  render() {
    const { t } = this.props;
    return (
      <div className="c-stats__box">
        <h3 className="u-title">
          {" "}
          {t("stats_by_payment_method.sells_by_payment_method")}
        </h3>
        <table className="o-table--list">
          <thead>
            <tr>
              <th>{t("stats_by_payment_method.payment_method")}</th>
              <th>
                {t("stats_by_payment_method.tickets")}
                <br />
                {t("stats_by_payment_method.sold")}
              </th>
              <th>
                {t("stats_by_payment_method.total_sells_1")}

                <br />
                {t("stats_by_payment_method.total_sells_2")}
              </th>
              <th>
                {t("stats_by_payment_method.charges_by_service_1")}
                <br />
                {t("stats_by_payment_method.charges_by_service_2")}
              </th>
              <th>{t("stats_by_payment_method.net_income")}</th>
            </tr>
          </thead>
          <tbody>
            {this.state.paymentMethods &&
              this.state.paymentMethods.map((el) => (
                <tr key={el.i}>
                  <td>{t(el.shortName)}</td>
                  <td>{el.ticketsCount}</td>
                  <td>
                    $<CurrencyNumber number={el.total} />
                  </td>
                  <td>
                    $<CurrencyNumber number={el.commission} />
                  </td>
                  <td>
                    $<CurrencyNumber number={el.income} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default withTranslation("common")(StatsByPaymentMethod);
