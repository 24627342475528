import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import FilterStats from "components/FilterStats";
import StatsOverview from "components/StatsOverview";
import StatsByPaymentMethod from "components/StatsByPaymentMethod";
import StatsByTicketType from "components/StatsByTicketType";
import Seating from "./seating";
import NotFound from "components/NotFound";
import Empty from "components/EmptyState";
import Loading from "components/Loading";
import Header from "components/Layout/Header";
import paymentMethodOrder from "features/App/GlobalStats/paymentMethodOrder";
import {
  _fetchDataEvent,
  _fetchDataOverview,
  _fetchDataSalesChannels,
  _fetchTicketTypesSales,
  _fetchPaymentMethodsSales,
} from "features/App/GlobalStats/fetchFunctions";

class EventStats extends Component {
  constructor(props) {
    super(props);
    const search = props.location.search;
    const params = new URLSearchParams(search);
    this.state = this._initialState(params.get("user_id"));
    this.state.session = params.get("q");
    this.state.token = params.get("token");
  }

  _initialState = (userId = null) => {
    return {
      userId: userId,
      startDate: "",
      endDate: "",
      eventsId: [this.props.match.params.id],
      sourcesId: [],
      income: 0,
      ticketsCount: 0,
      total: 0,
      commission: 0,
      paymentsSales: [],
      ticketTypesSales: [],
      salesChannelsData: [],
      active: false,
      loaded: false,
      currency: "MXN",
    };
  };

  _fetchAll = async (first = false) => {
    try {
      if (first) {
        await this._fetchDataSalesChannels();
        await this._fetchDataEvent();
      }
      await this._fetchDataOverview();
      await this._fetchPaymentMethodsSales();
      await this._fetchTicketTypesSales();
      this.setState({ loaded: true });
    } catch (err) {
      console.log(err);
      this.setState(this._initialState(this.state.userId));
      this.setState({ loaded: true });
    }
  };

  _updateFilters = (params) => {
    this.setState(
      {
        loaded: false,
        sourcesId: params.selectedSalesChannelsIds,
        startDate: params.selectedStartDate,
        endDate: params.selectedEndDate,
        income: 0,
        ticketsCount: 0,
        total: 0,
        commission: 0,
      },
      () => {
        this._fetchAll();
      }
    );
  };

  componentDidMount() {
    this._fetchDataEvent = _fetchDataEvent.bind(this);
    this._fetchDataSalesChannels = _fetchDataSalesChannels.bind(this);
    this._fetchDataOverview = _fetchDataOverview.bind(this);
    this._fetchPaymentMethodsSales = _fetchPaymentMethodsSales.bind(this);
    this._fetchTicketTypesSales = _fetchTicketTypesSales.bind(this);
    if (this.state.userId) {
      this._fetchAll(true);
    }
  }

  _renderStats = () => {
    const { t } = this.props;
    return (
      <>
        <Header />
        <FilterStats
          salesChannelsData={this.state.salesChannelsData}
          onChange={this._updateFilters}
        />

        {!this.state.loaded ? (
          <Loading />
        ) : (
          <>
            {this.state.ticketsCount <= 0 ? (
              <>
                {!this.state.startDate &&
                !this.state.endDate &&
                this.state.sourcesId.length === 0 ? (
                  <Empty
                    title={t("errors.empty_title")}
                    text={t("errors.empty_text")}
                  />
                ) : (
                  <NotFound />
                )}{" "}
              </>
            ) : (
              <>
                {this.state.useSeats && (
                  <Seating
                    eventId={this.state.eventsId[0]}
                    soldTickets={this.state.ticketsCount}
                    eventCapacity={this.state.realCapacity}
                    availableTickets={
                      this.state.realCapacity - this.state.ticketsCount
                    }
                    seatsEventKey={this.state.seatsEventKey}
                    details={this.state.details}
                    userId={this.state.userId}
                    session={this.state.session}
                    token={this.state.token}
                  />
                )}

                <StatsOverview
                  income={this.state.income}
                  ticketsCount={this.state.ticketsCount}
                  total={this.state.total}
                  commission={this.state.commission}
                  currency={this.state.currency}
                />

                <StatsByPaymentMethod
                  data={this.state.paymentsSales}
                  paymentMethodOrder={paymentMethodOrder}
                />

                <StatsByTicketType data={this.state.ticketTypesSales} />
              </>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    if (!this.state.eventsId || !this.state.userId) {
      return <NotFound />;
    }

    return (
      <div className="u-wrapper100 o-layout is-event-stats">
        {this.state.eventsId && this.state.userId && this._renderStats()}
      </div>
    );
  }
}

export default withTranslation("common")(EventStats);
