const paymentMethodOrder = {
  ["payment_methods.Banwire"]: 0,
  [`payment_methods.Oxxo`]: 1,
  [`payment_methods.Deposit`]: 2,
  [`payment_methods.BoletiaDeposit`]: 3,
  [`payment_methods.Paypal`]: 4,
  [`payment_methods.null`]: 5,
  [`payment_methods.Cash`]: 6,
  [`payment_methods.PhysicalTicket`]: 7,
  [`payment_methods.TicketBooth`]: 8,
  [`payment_methods.PosCard`]: 9,
  [`payment_methods.PosCash`]: 10,
};

export default paymentMethodOrder;
