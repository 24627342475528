import React from "react";
import { useTranslation } from "react-i18next";

function NotFound() {
  const { t } = useTranslation("common");

  return (
    <div className="c-empty">
      <img src="/assets/no-sales.png" className="c-empty__image" alt="" />
      <h3>{t("empty_filter_title")}</h3>
      <h3>{t("empty_filter_desc")}</h3>
    </div>
  );
}
export default NotFound;
