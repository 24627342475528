const BASE_URL = window.API_URL
const BOLETIA_URL = window.PLATFORM_URL

export const GRAPHQL = `${BASE_URL}/graphql`
export const GRAPHQL_BLT = `${BOLETIA_URL}/graphql/api/v1/global-stats`
export const EVENT_STATS = (eventId) => `${BOLETIA_URL}/events/${eventId}/analytics`

export const SEATS_SELECTION_TIME = 5
export const SEATS_RENDER_PUBLIC_KEY = '1653ee95-d3d1-489a-9446-9caed1c20e36'
export const SEATS_API_KEY = 'Yzk5YTMzZDAtMTRiOS00Zjc0LTljNGQtMjgyNjY2YTkyNGRm'  
export const SEATS_TIME_WAIT_FOR_RELASE_BOOKING = 2
export const SEATS_HOLD_PERIOD = 1680
export const SEATS_TIME_SPENDED_BEFORE_PAY = 30 
