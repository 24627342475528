import React, { Component } from 'react'
import SeatMap from 'components/SeatMap'

export default class Seating extends Component {
  constructor(props) {
    super(props)
    this.addSeatsScript()
    this.state = {
      userId: props.userId,
      eventId: props.eventId,
      soldTickets: props.soldTickets,
      eventCapacity: props.eventCapacity,
      availableTickets: props.availableTickets,
      seatsEventKey: props.seatsEventKey,
      details: props.details,
      session: props.session,
      token: props.token
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        eventId: this.props.eventId,
        soldTickets: this.props.soldTickets,
        eventCapacity: this.props.eventCapacity,
        availableTickets: this.props.availableTickets,
        seatsEventKey: this.props.seatsEventKey,
        details: this.props.details
      })
    }
  }

  async addSeatsScript ()  {
    let s = document.createElement('script')
    s.src = 'https://cdn.seatsio.net/chart.js'
    let head = document.getElementsByTagName('script')[0]
    head.parentNode.insertBefore(s, head)
  }

  render () {
    return (<>
      <ul className="c-stats__seating">
        <li>Asientos reservados: <strong> {this.state.soldTickets} </strong></li>  
        <li>Asientos disponibles: <strong> {this.state.availableTickets} </strong></li>
        <li>Asientos totales: <strong> {this.state.eventCapacity} </strong></li>
      </ul>
      <div className="c-stats__map" id="map">
        <SeatMap
          userId={this.state.userId}
          eventId={this.state.eventId}
          seatsEventKey={this.state.seatsEventKey}
          details={this.state.details}
          session={this.state.session}
          token={this.state.token}
        />
      </div>
    </>)
  }
}
