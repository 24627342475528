import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import CurrencyNumber from "components/CurrencyNumber";
import DateFormat from "components/DateFormat";
import { EVENT_STATS } from "api/constants";

class StatsByEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: this.props.data,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        events: this.props.data,
      });
    }
  }
  render() {
    const { t } = this.props;

    return (
      <div className="c-stats__box">
        <h3 className="u-title">Ventas por evento</h3>
        <table className="o-table--list">
          <thead>
            <tr>
              <th>Nombre del evento</th>
              <th>
                Fecha de
                <br />
                inicio
              </th>
              <th>
                Boletos
                <br />
                vendidos
              </th>
              <th>
                Ventas
                <br />
                totales
              </th>
              <th>
                Cargos por
                <br />
                servicio
              </th>
              <th>Ingreso neto</th>
            </tr>
          </thead>
          <tbody>
            {this.state.events &&
              this.state.events.map((el) => (
                <tr key={el.eventId}>
                  <td>
                    <a
                      href={`${EVENT_STATS(el.eventId)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {el.eventName}
                    </a>
                  </td>
                  <td>
                    <DateFormat date={el.eventStartDate} />
                  </td>
                  <td>{el.ticketsCount}</td>
                  <td>
                    $<CurrencyNumber number={el.total} />
                  </td>
                  <td>
                    $<CurrencyNumber number={el.commission} />
                  </td>
                  <td>
                    $<CurrencyNumber number={el.income} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default withTranslation("common")(StatsByEvent);
