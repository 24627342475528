import Storage from './Storage'
// import { LOGIN } from '../api/constants'

import moment from 'moment'

class Auth {
  retrieve () {
    try {
      let session = JSON.parse(Storage.retrieve('auth'))
      const refreshOn =
        session.created_at && session.expires_in
          ? moment.unix(session.created_at).add(session.expires_in, 's')
          : null

      session.token_expired = refreshOn ? refreshOn.isBefore(moment()) : true

      return session
    } catch (error) {
      return null
    }
  }

  saveAuth (data) {
    return Storage.save('auth', JSON.stringify(data))
  }

  removeAuth () {
    return Storage.remove('auth')
  }

  isAuthorized () {
    try {
      let auth = this.retrieve()
      return !!auth.access_token && !auth.token_expired
    } catch (e) {
      return false
    }
  }

  adminAuthorized () {
    try {
      let auth = this.retrieve()
      return this.isAuthorized() && (!!auth.is_super_admin || !!auth.is_owner)
    } catch (e) {
      return false
    }
  }

  async refreshToken () {
    try {
      let auth = this.retrieve()
      const params = {
        grant_type: 'refresh_token',
        refresh_token: auth.refresh_token
      }

      let result = await fetch('http://localhost:3001/oauth/token', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      })

      result && this.saveAuth(await result.json())
      return await this.retrieve()
    } catch (error) {
      return false
    }
  }
}

let auth = null
if (!auth) {
  auth = new Auth()
}

export default auth
