import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import DataTable from "components/DataTable";
import CustomCalendar from "components/CustomCalendar";
import moment from "moment";

function subfilterDates(firstSale = null) {
  return [
    {
      filterActive: false,
      label: "filter_dates.today",
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
    },
    {
      filterActive: false,
      label: "filter_dates.yesterday",
      startDate: moment()
        .add(-1, "days")
        .startOf("day"),
      endDate: moment()
        .add(-1, "days")
        .endOf("day"),
    },
    {
      filterActive: false,
      label: "filter_dates.last_cutoff",
      startDate: moment()
        .add("-2", "week")
        .day(6)
        .startOf("day"),
      endDate: moment()
        .add("-1", "week")
        .day(5)
        .endOf("day"),
    },
    {
      filterActive: false,
      label: "filter_dates.since_beggining",
      startDate: firstSale,
      endDate: moment().endOf("day"),
    },
  ];
}

class FilterStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeUsers: false,
      activeEvents: false,
      activeDates: false,
      salesChannelsData: props.salesChannelsData,
      eventsData: props.eventsData,
      firstSale: props.firstSale ? props.firstSale : moment().startOf("day"),
      filterSalesChannelsData: props.salesChannelsData,
      filterEventsData: props.eventsData,
      selectedSalesChannelsIds: [],
      selectedEventsIds: [],
      selectedStartDate: "",
      selectedEndDate: "",
      restore: true,
      subfilterDates: props.firstSale
        ? subfilterDates(
            moment(props.firstSale)
              .utc()
              .startOf("day")
          )
        : subfilterDates(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.firstSale !== this.props.firstSale && this.props.firstSale) {
      this.setState({
        subfilterDates: subfilterDates(
          moment(this.props.firstSale)
            .utc()
            .startOf("day")
        ),
      });
    }

    if (prevProps !== this.props) {
      this.setState({
        salesChannelsData: this.props.salesChannelsData,
        eventsData: this.props.eventsData,
        firstSale: this.props.firstSale
          ? this.props.firstSale
          : moment().startOf("day"),
        filterSalesChannelsData: this.props.salesChannelsData,
        filterEventsData: this.props.eventsData,
      });
    }
  }

  handleClickUsers = (evt) => {
    this.setState({
      activeUsers: !this.state.activeUsers,
      activeEvents: false,
      activeDates: false,
    });
  };

  handleClickEvents = (evt) => {
    this.setState({
      activeUsers: false,
      activeEvents: !this.state.activeEvents,
      activeDates: false,
    });
  };

  handleClickDates = (evt) => {
    this.setState({
      activeUsers: false,
      activeEvents: false,
      activeDates: !this.state.activeDates,
    });
  };

  _handleEventLabels = (selectedIds, data) => {
    let filterLabel = `${selectedIds.length} eventos`;
    if (selectedIds.length <= 0) {
      filterLabel = "Todos los eventos";
    } else if (selectedIds.length === 1) {
      data
        .filter((el) => el.id === selectedIds[0])
        .map((el) => (filterLabel = `${el["eventName"]}`));
    }
    return filterLabel;
  };

  _handleChannelLabels = (selectedIds, data) => {
    let filterLabel = `${selectedIds.length} canales de venta`;
    if (selectedIds.length <= 0) {
      filterLabel = "all_sales_channel";
    } else if (selectedIds.length === 1) {
      data
        .filter((el) => el.id === selectedIds[0])
        .map((el) => (filterLabel = `${el["fullName"]}`));
    } else {
      let rolesName = data
        .filter((el) => selectedIds.includes(el.id))
        .map((el) => el.roleName);
      let unique = [...new Set(rolesName)];
      if (unique.length === 1) {
        if (unique[0] === "seller") {
          filterLabel = `${selectedIds.length} vendedores`;
        } else {
          filterLabel = `${selectedIds.length} insiders`;
        }
      }
    }
    return filterLabel;
  };

  _handleDateLabels = () => {
    let label = "since_beggining";
    if (this.state.dateLabel) {
      label = `${this.state.dateLabel}`;
    } else if (this.state.selectedStartDate && this.state.selectedEndDate) {
      label = `${this.state.selectedStartDate.format(
        "L"
      )} - ${this.state.selectedEndDate.format("L")}`;
    }
    return label;
  };

  _handleCleanFilters = () => {
    this.setState(
      {
        activeUsers: false,
        activeEvents: false,
        activeDates: false,
        filterSalesChannelsData: this.props.salesChannelsData,
        filterEventsData: this.props.eventsData,
        firstSale: this.props.firstSale
          ? this.props.firstSale
          : moment().startOf("day"),
        selectedSalesChannelsIds: [],
        selectedEventsIds: [],
        selectedStartDate: "",
        selectedEndDate: "",
        dateLabel: "",
        restore: true,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  _submitFilters = () => {
    this.setState(
      {
        activeUsers: false,
        activeEvents: false,
        activeDates: false,
        restore: false,
      },
      () => {
        this.props.onChange(this.state);
      }
    );
  };

  _updateUserFilter = (params) => {
    if (params.submit) {
      return this._submitFilters();
    }
    this.setState({
      activeUsers: params.active,
      selectedSalesChannelsIds: params.selected,
      restore: false,
    });
  };

  _updateEventFilter = (params) => {
    if (params.submit) {
      return this._submitFilters();
    }
    this.setState({
      activeEvents: params.active,
      selectedEventsIds: params.selected,
      restore: false,
    });
  };

  _updateDateFilter = (params) => {
    if (params.submit) {
      return this._submitFilters();
    }

    this.setState(
      {
        activeDates: params.active,
        selectedStartDate: params.startDate,
        selectedEndDate: params.endDate,
        dateLabel: params.label,
        restore: false,
      },
      () => {
        if (params.cancel) {
          return this._submitFilters();
        }
      }
    );
  };

  render() {
    const { t } = this.props;

    const columnsEvents = [
      {
        dataField: "id",
        text: "id",
        sort: true,
      },
      {
        dataField: "eventName",
        text: t("columns.events.name"),
        sort: true,
        filterValue: (cell) => {
          return cell.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        },
      },
      {
        dataField: "roleName",
        text: t("columns.events.role"),
        sort: true,
      },
      {
        dataField: "startDate",
        text: t("columns.events.date_start"),
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === "asc") {
            return b.props.date > a.props.date;
          }
          return -1; // desc
        },
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
      },
    ];

    const columnsSalesChannels = [
      {
        dataField: "id",
        text: "id",
        hidden: true,
      },
      {
        dataField: "fullName",
        text: t("columns.sales_channel.full_name"),
        sort: true,
        filterValue: (cell) => {
          return cell.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        },
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "eventsCount",
        text: t("columns.sales_channel.events_assigned"),
        sort: true,
      },
    ];

    const subfilterUsers = [
      {
        filterActive: false,
        label: t("subfilter.boletia_sales_online"),
        filter: [{ fullName: "Boletia.com" }],
      },
      {
        filterActive: false,
        label: "Insiders",
        filter: [
          { roleName: "insider" },
          { roleName: "partner" },
          { roleName: "owner" },
        ],
      },
      {
        filterActive: false,
        label: t("subfilter.sellers"),
        filter: [
          { roleName: "seller" },
          { roleName: "partner" },
          { roleName: "owner" },
        ],
      },
    ];

    const subfilterEvents = [
      {
        filterActive: false,
        label: t("subfilter.active_events"),
        filter: [{ status: t("events.status.active") }],
      },
      {
        filterActive: false,
        label: t("subfilter.inactive_events"),
        filter: [
          { status: t("events.status.not_published") },
          { status: t("events.status.archived") },
        ],
      },
      {
        filterActive: false,
        label: t("subfilter.my_events"),
        filter: [{ roleName: t("role_names.owner") }],
      },
      {
        filterActive: false,
        label: t("subfilter.my_collab_events"),
        filter: [
          { roleName: t("role_names.partner") },
          { roleName: t("role_names.analyst") },
        ],
      },
    ];

    return (
      <>
        <div className="c-filter__container">
          <h4>{t("filter_stats.filter_by")}</h4>
          <div className="c-filter">
            {this.state.salesChannelsData && (
              <>
                <button
                  className={
                    this.state.activeUsers ||
                    this.state.selectedSalesChannelsIds.length > 0
                      ? "c-filter__button is-active is-channel"
                      : "c-filter__button is-channel"
                  }
                  onClick={this.handleClickUsers}
                >
                  {t(
                    this._handleChannelLabels(
                      this.state.selectedSalesChannelsIds,
                      this.state.salesChannelsData
                    )
                  )}
                </button>
              </>
            )}
            {this.state.eventsData && (
              <>
                <button
                  className={
                    this.state.activeEvents ||
                    this.state.selectedEventsIds.length > 0
                      ? "c-filter__button is-active"
                      : "c-filter__button"
                  }
                  onClick={this.handleClickEvents}
                >
                  {t(
                    this._handleEventLabels(
                      this.state.selectedEventsIds,
                      this.state.eventsData,
                      "eventos",
                      "eventName"
                    )
                  )}
                </button>
              </>
            )}
            <button
              className={
                this.state.activeDates ||
                (this.state.selectedStartDate && this.state.selectedEndDate)
                  ? "c-filter__button is-active is-date"
                  : "c-filter__button is-date"
              }
              onClick={this.handleClickDates}
            >
              {t(this._handleDateLabels())}
            </button>
          </div>

          <div className="c-filter__actions">
            <button
              className="u-button--base u-button--link"
              onClick={this._handleCleanFilters}
            >
              {" "}
              {t("filter_stats.reset_filter")}{" "}
            </button>
            <button
              className="u-button--base u-button--secondary"
              onClick={this._submitFilters}
            >
              {" "}
              {t("filter_stats.update_stats")}{" "}
            </button>
          </div>
        </div>
        {this.state.salesChannelsData && (
          <>
            <DataTable
              active={this.state.activeUsers}
              data={this.state.filterSalesChannelsData}
              columns={columnsSalesChannels}
              placeholderSearchBar={t("data_table.look_user_by")}
              continueLabel={t("update")}
              extraClasses="is-users"
              filters={subfilterUsers}
              selected={this.state.selectedSalesChannelsIds}
              restore={this.state.restore}
              onChange={this._updateUserFilter}
            />
          </>
        )}
        {this.state.eventsData && (
          <>
            <DataTable
              active={this.state.activeEvents}
              data={this.state.filterEventsData}
              columns={columnsEvents}
              placeholderSearchBar={t("data_table.look_event_by")}
              continueLabel={t("update")}
              extraClasses="is-events"
              filters={subfilterEvents}
              selected={this.state.selectedEventsIds}
              restore={this.state.restore}
              onChange={this._updateEventFilter}
            />
          </>
        )}
        <CustomCalendar
          active={this.state.activeDates}
          filters={this.state.subfilterDates}
          startDate={this.state.selectedStartDate}
          endDate={this.state.selectedEndDate}
          continueLabel={t("update")}
          restore={this.state.restore}
          onChange={this._updateDateFilter}
        />
      </>
    );
  }
}
export default withTranslation("common")(FilterStats);
