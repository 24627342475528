export default class Storage {
  static save (key, value) {
    try {
      return localStorage.setItem(key, value)
    } catch (error) {
      return error('Error setting key')
    }
  }
  static retrieve (key) {
    let value = null
    try {
      value = localStorage.getItem(key)
      if (!value) {
        return null
      }
    } catch (error) {
      return null
    }
    return value
  }

  static remove (key) {
    return localStorage.removeItem(key)
  }

  static error (message) {
    return new Promise((resolve, reject) => reject(Error(message)))
  }
}
