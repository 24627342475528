import React from "react";

import { useTranslation } from "react-i18next";

function StatsByTicketType(props) {
  const { data: ticket_types } = props;

  const { t } = useTranslation("common");

  return (
    <div className="c-stats__box">
      <h3 className="u-title">{t("ticket_type_stats.sells_by_ticket")}</h3>
      <table className="o-table--list">
        <thead>
          <tr>
            <th>{t("ticket_type_stats.ticket_type")}</th>
            <th>
              {t("ticket_type_stats.tickets1")}
              <br />
              {t("ticket_type_stats.in_proccess")}
            </th>
            <th>
              {t("ticket_type_stats.tickets")}
              <br />
              {t("ticket_type_stats.pending")}
            </th>
            <th>
              {t("ticket_type_stats.tickets")}
              <br />
              {t("ticket_type_stats.sold")}
            </th>
          </tr>
        </thead>
        <tbody>
          {ticket_types &&
            ticket_types.map((el) => (
              <tr key={el.id + "-" + el.name}>
                <td>
                  {el.id} - {el.name}{" "}
                </td>
                <td>{el.processingTicketsCount}</td>
                <td>{el.pendingTicketsCount}</td>
                <td>{el.ticketsCount}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}
export default StatsByTicketType;
