import React, { Component } from 'react'
import { SEATS_RENDER_PUBLIC_KEY } from 'api/constants'
import {
  _fetchTicketsByCategory,
  _fetchTicketsInfo
} from 'features/App/GlobalStats/fetchFunctions'


export default class SeatMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userId: props.userId,
      eventId: props.eventId,
      details: props.details,
      seatsEventKey: props.seatsEventKey,
      disabledCategories: {},
      soldTickets: {},
      session: props.session,
      token: props.token
    }
  }

  componentDidMount() {
    this._fetchTicketsByCategory = _fetchTicketsByCategory.bind(this)
    this._fetchTicketsInfo = _fetchTicketsInfo.bind(this)
    if (this.state.userId) {
      this._fetchAll(true)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        eventId: this.props.eventId,
        seatsEventKey: this.props.seatsEventKey,
        details: this.props.details
      })
    }
  }

  _fetchAll = async function () {
    await this._fetchTicketsInfo()
    await this._fetchTicketsByCategory()
    this.setConfig()
    let interval = setInterval(() => {
      if (window.seatsio) {
        clearInterval(interval)
        new window.seatsio.SeatingChart(this.state.config).render()
      }
    }, 50)
  }

  disabledCategories () {
    let details = JSON.parse(this.state.details)
    let usedCategories = new Set()
    this.state.ticketsInfo.map((el) => {
      return usedCategories.add(el.usedCategories)
    })
    let categories = new Set()
    if (details.categories) {
     details.categories.map((el) => {
      return categories.add(el.key)
     })
    }
    let disabledCategories = [...categories].filter((el) => !usedCategories.has(el))
    this.setState({
      disabledCategories: disabledCategories
    })
  }

  soldTickets () {
    let soldTickets = {}
    this.state.ticketsInfo.map((el) => {
      if (el.seatSection !== '') {
        return soldTickets[`${el.seatSection}-${el.seatNumber}-${el.rowNumber}`] = true
      } else {
        return soldTickets[`${el.seatNumber}-${el.rowNumber}`] = true
      }
    })
    this.setState({
      soldTickets: soldTickets
    })
  }

  setConfig = () => {
    this.setState({
      config: {
        language: 'es',
        divId: 'map',
        mode: 'static',
        fitTo: 'width',
        publicKey: SEATS_RENDER_PUBLIC_KEY,
        event: `${this.state.seatsEventKey}`,
        isObjectSelectable: function (object, isSelectable, extraConfig) {
          if (object.type === 'generalAdmission') {
            if (extraConfig.totals[object.category.label]) {
              object.ownLabel = extraConfig.totals[object.category.label] + '/' + object.capacity
            }
          }
          if (object.status === 'free') {
            return true
          }
          return false
        },
        objectLabel: function (object, label) {
          if (object.type !== 'seat') {
            return 'Es general'
          }
          return label
        },
        messages: { 
          notAvailable: 'Reservado'
        },
        extraConfig: {
          unavailableCategories: this.state.disabled_categories,
          unavailableSeats: this.state.soldTickets,
          totals: this.state.ticketsByCategory
        },
        cursorTooltip: {
          showActionHint: true,
          showAvailability: false,
          showCategory: true,
          showLabel: true,
          showPricing: true,
          showUnavailableNotice: true,
          stylizedLabel: true,
        }
      }
    })    
  }
  
  render () {
    return ( <>
      {<div id='map'></div>}
    </> )
  }

}
