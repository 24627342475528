import React from "react";
import request from "api/request";
import { GRAPHQL_BLT } from "api/constants";
import { deserialise } from "kitsu-core";
import DateFormat from "components/DateFormat";
import moment from "moment";

const platformSalesChannel = {
  id: "0",
  fullName: "Boletia.com",
  email: "",
  roleName: "platform",
  eventsCount: "",
};

function _buildExtraQuery(params) {
  var extraQuery = {};
  if (params.eventsId.length > 0) {
    extraQuery["eventIds"] = `[${params.eventsId}]`;
  }
  if (params.sourcesId.length > 0) {
    extraQuery["channelIds"] = `[${params.sourcesId}]`;
  }
  if (params.startDate && params.endDate) {
    extraQuery["startDate"] = `"${params.startDate.format()}"`;
    extraQuery["endDate"] = `"${params.endDate.format()}"`;
  }
  return extraQuery;
}

export const _fetchCountEventsByUser = async function () {
  var params = {
    functionName: "countEventsByUser",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      rolesName: "[partner,analyst,owner]",
    },
    payload: "count",
  };

  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let countEvents = await deserialise(response.body);
    if (countEvents !== null) {
      countEvents = await deserialise(countEvents.data);
      this.setState({
        countEvents: countEvents.countEventsByUser[0].count,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const _fetchDataEventsByUser = async function (
  limit = null,
  offset = null
) {
  var configParams = {};
  if (limit) {
    configParams["limit"] = limit;
  }
  if (offset) {
    configParams["offset"] = offset;
  }

  var params = {
    functionName: "eventsByUser",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      rolesName: "[partner,analyst,owner]",
      ...configParams,
    },
    payload: "id eventName status startDate endDate roleName activatedAt",
  };

  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let events = await deserialise(response.body);
    if (events) {
      events = await deserialise(events.data);
      let formatEvents = [...events.eventsByUser];
      if (formatEvents) {
        formatEvents = await _formatEvents(formatEvents, this.props);
        if (this.state.eventsData) {
          this.setState({
            eventsData: this.state.eventsData.concat(formatEvents),
          });
        } else {
          this.setState({
            eventsData: formatEvents,
          });
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

async function _formatEvents(events, props) {
  for (let el in events) {
    // events[el].roleName = i18n.t('globalStatistics.roleNames.' + (events[el].roleName))
    events[el].roleName = props.t("role_names." + events[el].roleName);
    if (
      events[el].endDate &&
      events[el].activatedAt &&
      moment(events[el].endDate).utc() > moment().utc()
    ) {
      events[el].status = "events.status.active";
    } else {
      events[el].status =
      props.t("events.status." + events[el].status);
    }

    if (events[el].startDate) {
      events[el].startDate = <DateFormat date={events[el].startDate} />;
    }
  }
  return events;
}

export const _fetchDataSalesChannels = async function () {
  var eventParams = {};
  if (this.state.eventsId.length > 0) {
    eventParams["eventIds"] = `${this.state.eventsId}`;
  }
  var params = {
    functionName: "salesChannels",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      rolesName: "[owner, seller, insider, partner]",
      ...eventParams,
    },
    payload: "id fullName email roleName eventsCount",
  };

  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let collaborators = await deserialise(response.body);
    if (collaborators) {
      collaborators = await deserialise(collaborators.data);
      let salesChannels = [...collaborators.salesChannels];
      salesChannels.push(platformSalesChannel);

      this.setState({
        salesChannelsData: salesChannels,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const _fetchDataEvent = async function () {
  var params = {
    functionName: "events",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      ids: `${this.state.eventsId}`,
      eventIds: `${this.state.eventsId}`,
    },
    payload: "seatsEventKey useSeats realCapacity layoutDetails currency",
  };

  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let body = await deserialise(response.body);
    if (body) {
      let data = await deserialise(body.data);
      let event = [...data.events][0];
      this.setState({
        useSeats: event.useSeats,
        realCapacity: event.realCapacity,
        seatsEventKey: event.seatsEventKey,
        details: event.layoutDetails,
        currency: event.currency,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const _fetchOwner = async function (u) {
  var params = {
    functionName: "users",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      email: `"${u}"`,
    },
    payload: "id",
  };
  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let body = await deserialise(response.body);
    if (body) {
      let data = await deserialise(body.data);
      let users = data.users;
      let user =
        users && users[0] && users[0].id ? parseInt(users[0].id) : null;
      this.setState({ userId: user });
    }
  } catch (error) {
    console.log(error);
    this.setState({ userId: null });
  }
};

export const _fetchDataOverview = async function () {
  var extraQuery = _buildExtraQuery(this.state);
  var params = {
    functionName: "totalSales",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      ...extraQuery,
    },
    payload: "ticketsCount total commission income firstSale",
  };
  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let salesOverview = await deserialise(response.body);
    if (salesOverview !== null) {
      salesOverview = await deserialise(salesOverview.data);
      let totalSales = salesOverview.totalSales[0];

      this.setState({
        income: totalSales.income,
        ticketsCount: totalSales.ticketsCount,
        total: totalSales.total,
        commission: totalSales.commission,
        firstSale: totalSales.firstSale,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const _fetchPaymentMethodsSales = async function () {
  var extraQuery = _buildExtraQuery(this.state);
  var params = {
    functionName: "totalSales",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      filterBy: "payment_methods",
      ...extraQuery,
    },
    payload: "paymentMethodType ticketsCount total commission income",
  };
  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let body = await deserialise(response.body);
    if (body) {
      let salesPM = await deserialise(body.data);
      this.setState({
        paymentsSales: salesPM.totalSales,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const _fetchEventsSales = async function () {
  var extraQuery = _buildExtraQuery(this.state);
  var params = {
    functionName: "totalSales",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      filterBy: "events",
      ...extraQuery,
    },
    payload:
      "eventId eventName ticketsCount total commission income eventStartDate",
  };
  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let salesEvent = await deserialise(response.body);
    if (salesEvent) {
      salesEvent = await deserialise(salesEvent.data);
      this.setState({
        eventsSales: salesEvent.totalSales,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const _fetchTicketTypesSales = async function () {
  var extraQuery = _buildExtraQuery(this.state);
  var params = {
    functionName: "ticketTypesByEvent",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      ...extraQuery,
    },
    payload: "id name ticketsCount pendingTicketsCount processingTicketsCount",
  };

  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let ticketTypesByEvent = await deserialise(response.body);
    if (ticketTypesByEvent) {
      ticketTypesByEvent = await deserialise(ticketTypesByEvent.data);
      this.setState({
        ticketTypesSales: ticketTypesByEvent.ticketTypesByEvent,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const _fetchTicketsInfo = async function () {
  var params = {
    functionName: "ticketsInfoByEvent",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      eventId: `${this.state.eventId}`,
    },
    payload: "seatSection seatNumber rowNumber usedCategories",
  };

  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let body = await deserialise(response.body);
    if (body) {
      let data = await deserialise(body.data);
      let ticketsInfo = [...data.ticketsInfoByEvent];
      this.setState(
        {
          ticketsInfo: ticketsInfo,
        },
        () => {
          this.disabledCategories();
          this.soldTickets();
        }
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const _fetchTicketsByCategory = async function () {
  var params = {
    functionName: "ticketsByCategory",
    params: {
      session: `${this.state.session}`,
      token: `${this.state.token}`,
      userId: `${this.state.userId}`,
      eventId: `${this.state.eventId}`,
    },
    payload: " total seatSection",
  };

  try {
    let response = await request.post(GRAPHQL_BLT, params, false);
    let body = await deserialise(response.body);
    if (body) {
      let data = await deserialise(body.data);
      let ticketsByCategory = [...data.ticketsByCategory];
      let section = {};
      ticketsByCategory.map((el) => {
        return (section[`${el.seatSection}`] = el.total);
      });
      this.setState({
        ticketsByCategory: section,
      });
    }
  } catch (error) {
    console.log(error);
  }
};
