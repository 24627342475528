import React, { Component } from "react";
import FilterStats from "components/FilterStats";
import FilterStatsSuperAdmin from "components/FilterStatsSuperAdmin";
import StatsOverview from "components/StatsOverview";
import StatsByPaymentMethod from "components/StatsByPaymentMethod";
import StatsByEvent from "components/StatsByEvent";
import NotFound from "components/NotFound";
import Empty from "components/EmptyState";
import Loading from "components/Loading";
import paymentMethodOrder from "./paymentMethodOrder";
import {
  _fetchDataEventsByUser,
  _fetchCountEventsByUser,
  _fetchDataOverview,
  _fetchDataSalesChannels,
  _fetchEventsSales,
  _fetchPaymentMethodsSales,
  _fetchOwner,
} from "./fetchFunctions";

import { withTranslation } from "react-i18next";

class GlobalStats extends Component {
  constructor(props) {
    super(props);
    const search = props.location.search;
    const params = new URLSearchParams(search);
    this.state = this._initialState(
      params.get("user_id"),
      params.get("admin_id")
    );
    this.state.firstTime = true;
    this.state.session = params.get("q");
    this.state.token = params.get("token");
  }

  _initialState = (userId = null, superAdminId = null) => {
    return {
      superAdminId: superAdminId,
      userId: userId,
      startDate: "",
      endDate: "",
      firstSale: "",
      eventsId: [],
      sourcesId: [],
      income: 0,
      ticketsCount: 0,
      total: 0,
      commission: 0,
      paymentsSales: [],
      eventsSales: [],
      eventsData: [],
      salesChannelsData: [],
      active: false,
      loaded: false,
      firstTime: false,
      currency: "MXN",
    };
  };

  _fetchAll = async (first = false) => {
    try {
      if (first) {
        await this._fetchDataSalesChannels();
        await this._fetchCountEventsByUser();
        if (this.state.countEvents < 200) {
          await this._fetchDataEventsByUser();
        } else {
          await this._fetchDataEventsByUser(50);
          this._fetchDataEventsWithOffset();
        }
      }
      await this._fetchDataOverview();
      await this._fetchPaymentMethodsSales();
      if (this.state.eventsId.length > 0 || this.state.sourcesId.length > 0) {
        await this._fetchEventsSales();
      }
      this.setState({ loaded: true });
    } catch (err) {
      console.log(err);
      this.setState(
        this._initialState(this.state.userId, this.state.superAdminId)
      );
      this.setState({ loaded: true });
    }
  };

  _fetchDataEventsWithOffset = async (limit = 50) => {
    for (
      let offset = limit;
      offset <= this.state.countEvents;
      offset = offset + limit
    ) {
      this._fetchDataEventsByUser(limit, offset);
    }
  };

  _updateOwner = async (userFilter) => {
    try {
      await this.setState({
        loaded: false,
      });
      if (isNaN(userFilter)) {
        await this._fetchOwner(userFilter);
      } else {
        this.setState({
          userId: userFilter,
        });
      }

      this.setState({
        firstTime: false,
      });

      if (this.state.userId) {
        this.setState(
          this._initialState(this.state.userId, this.state.superAdminId),
          () => {
            this._fetchAll(true);
          }
        );
      }
    } catch (err) {
      console.log(err);
      this.setState({
        loaded: true,
        userId: null,
      });
    }
  };

  _updateFilters = (params) => {
    this.setState(
      {
        loaded: false,
        eventsId: params.selectedEventsIds,
        sourcesId: params.selectedSalesChannelsIds,
        startDate: params.selectedStartDate,
        endDate: params.selectedEndDate,
        income: 0,
        ticketsCount: 0,
        total: 0,
        commission: 0,
      },
      () => {
        this._fetchAll();
      }
    );
  };

  componentDidMount() {
    this._fetchDataEventsByUser = _fetchDataEventsByUser.bind(this);
    this._fetchCountEventsByUser = _fetchCountEventsByUser.bind(this);
    this._fetchDataSalesChannels = _fetchDataSalesChannels.bind(this);
    this._fetchDataOverview = _fetchDataOverview.bind(this);
    this._fetchPaymentMethodsSales = _fetchPaymentMethodsSales.bind(this);
    this._fetchEventsSales = _fetchEventsSales.bind(this);
    this._fetchOwner = _fetchOwner.bind(this);
    if (this.state.userId) {
      this._fetchAll(true);
    }
  }

  componentDidUpdate(){
    // this._fetchAll()
  }

  _renderStats = () => {
    return (
      <>
        {this.state.eventsData.length > 0 && (
          <FilterStats
            salesChannelsData={this.state.salesChannelsData}
            eventsData={this.state.eventsData}
            firstSale={this.state.firstSale}
            countEvents={this.state.countEvents}
            onChange={this._updateFilters}
          />
        )}

        {!this.state.loaded ? (
          <Loading />
        ) : (
          <>
            {this.state.eventsData.length > 0 ? (
              <>
                {this.state.ticketsCount <= 0 ? (
                  <NotFound />
                ) : (
                  <>
                    <StatsOverview
                      income={this.state.income}
                      ticketsCount={this.state.ticketsCount}
                      total={this.state.total}
                      commission={this.state.commission}
                      currency={this.state.currency}
                    />

                    <StatsByPaymentMethod
                      data={this.state.paymentsSales}
                      paymentMethodOrder={paymentMethodOrder}
                    />

                    {(this.state.eventsId.length > 0 ||
                      this.state.sourcesId.length > 0) && (
                      <StatsByEvent data={this.state.eventsSales} />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {this.state.eventsData.length <= 0 && (
                  <Empty
                    title="Aún no tienes ventas registradas"
                    text="Te invitamos a que te acerques a nosotros en hola@boletia.com para que te brindemos algunas sugerencias y empieces a vender."
                  />
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    if (!this.state.superAdminId && !this.state.userId) {
      return <NotFound />;
    }

    return (
      <div className="u-wrapper100 o-layout is-global-stats">
        {this.state.superAdminId && (
          <>
            <FilterStatsSuperAdmin
              userId={this.state.userId}
              onChange={this._updateOwner}
            />
          </>
        )}

        {this.state.userId ? (
          this._renderStats()
        ) : (
          <>
            {!this.state.firstTime && (
              <Empty
                title="No se encontró al usuario seleccionado"
                text="Intenta buscar con otros valores."
              />
            )}
          </>
        )}
      </div>
    );
  }
}
export default withTranslation("common")(GlobalStats)