import React from 'react'
import { withRouter } from 'react-router'
import { Route, Redirect } from 'react-router-dom'
import auth from './auth'

class _ScrollToTop extends React.Component {
  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }
}

const ScrollToTop = withRouter(_ScrollToTop)

const AuthRequiredRoute = (props) => {
  const { render, ...routeProps } = props
  return (
    <Route {...routeProps} render={props => (
      auth.isAuthorized() ? (
        render(props)
      ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )
    )} />
  )
}

export { AuthRequiredRoute, ScrollToTop }
