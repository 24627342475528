import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import CurrencyNumber from "components/CurrencyNumber";

class StatsOverview extends Component {
  render() {
    const { t, currency } = this.props;

    return (
      <div className="c-stats__overview">
        <h6 className="c-stats__label">{t("stats_overview.net_income")}</h6>
        <h1 className="c-stats__title">
          $<CurrencyNumber number={this.props.income} />
          {currency}
        </h1>
        <ul className="c-stats__list">
          <li>
            <h6 className="c-stats__label">{t("stats_overview.total_sale")}</h6>
            <h3 className="c-stats__subtitle">
              $<CurrencyNumber number={this.props.total} />
              {currency}
            </h3>
          </li>
          <li>
            <h6 className="c-stats__label">
              {t("stats_overview.charges_by_service")}
            </h6>
            <h3 className="c-stats__subtitle">
              $<CurrencyNumber number={this.props.commission} />
              {currency}
            </h3>
          </li>
          <li>
            <h6 className="c-stats__label">
              {t("stats_overview.tickets_sold")}
            </h6>
            <h3 className="c-stats__subtitle">{this.props.ticketsCount}</h3>
          </li>
        </ul>
      </div>
    );
  }
}
export default withTranslation("common")(StatsOverview);
