import React, { Component } from "react";

import { withTranslation } from "react-i18next";

class SubFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: this.props.filters,
      data: this.props.data,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        filters: this.props.filters,
        data: this.props.data,
      });
    }
  }

  handleClickFilter = (index) => {
    let subfilters = [...this.state.filters];
    let subdata = [];
    subfilters.map((el, i) => {
      if (i === index) {
        el.filterActive = true;
        el.filter.forEach((filter) => {
          let key = Object.keys(filter)[0];
          this.state.data
            .filter((element) => element[key] === filter[key])
            .map((elm) => subdata.push(elm));
        });
        return el;
      } else {
        return (el.filterActive = false);
      }
    });
    this.props.onChange(subdata, subfilters, []);
  };

  handleCleanFilters = () => {
    let subfilters = [...this.state.filters];
    subfilters.map((el) => {
      return (el.filterActive = false);
    });
    this.props.onChange(this.state.data, subfilters, []);
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.filters && (
          <div className="c-results__button-group">
            {this.state.filters.map((el, i) => (
              <button
                key={i}
                className={
                  el.filterActive
                    ? "c-results__button is-active"
                    : "c-results__button"
                }
                onClick={() => this.handleClickFilter(i)}
              >
                {el.label}
              </button>
            ))}

            <button
              className="u-button--base u-button--link"
              onClick={this.handleCleanFilters}
            >
              {t("filter_stats.reset_filter")}
            </button>
          </div>
        )}
      </>
    );
  }
}
export default withTranslation("common")(SubFilter);
